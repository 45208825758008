import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

let params = {}
let isManualChange = false

export default function useSearchParams(defaultFilter = {}, query) {
    const router = useRouter()
    const [searchParams, setSearchParams] = useState({})
    const isMainCatalogPage = router.pathname === '/catalog'

    const handleSearchParams = (obj) => {
        const { name, value } = obj
        isManualChange = true
        setSearchParams({ ...searchParams, [name]: value })
    }

    const removeProperties = (obj, propertiesToRemove) => {
        for (let prop of propertiesToRemove) {
            delete obj[prop]
        }
    }

    useEffect(() => {
        if (isManualChange) {
            if (Object.values(searchParams || []).length > 0) {
                for (const param in searchParams) {
                    if (searchParams[param] !== defaultFilter[param]) {
                        if (param === 'rooms') {
                            params[param] = searchParams[param].join()

                            if (!params[param]) {
                                delete params[param]
                                break
                            }
                            break
                        }

                        searchParams[param]
                            ? (params[param] = searchParams[param])
                            : delete params[param]
                    } else {
                        delete params[param]
                    }
                }

                let url = ''
                if (isMainCatalogPage) {
                    url =
                        Object.values(params || []).length > 0
                            ? `/catalog/?${new URLSearchParams(params)}`
                            : '/catalog/'
                } else {
                    const baseUrl = router.asPath.split('/').slice(0, 4).join('/')
                    const selectedParams = removeProperties(params, ['slug', 'roominess'])
                    url =
                        Object.values(params || []).length > 0 && !!selectedParams
                            ? `${baseUrl}/?${new URLSearchParams(selectedParams)}`
                            : `${baseUrl}`
                }

                router.replace(url, undefined, { shallow: true })
            }
        }
    }, [searchParams])

    if (Object.values(query || []).length > 0 && !isManualChange) {
        isManualChange = false
        params = { ...query }
    } else if (!query && !isManualChange) {
        Object.keys(params).forEach((param) => {
            params[param] = false
        })
    }

    return {
        searchParams: params,
        setSearchParams: handleSearchParams,
        resetParams: () => {
            for (const param in params) {
                delete params[param]
            }
            setSearchParams({})
        },
        isManualChange: isManualChange,
    }
}
