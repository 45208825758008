import { useEffect, useState } from "react";
import Link from "next/link";
import { DISCOUNT } from "../../../data/common";
import useSearchParams from "../../../hooks/useSearchParams";
import {useRouter} from "next/router";
import {list} from "../../../api/flats";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {types} from "../../../actions/catalog";


export default function VisualSolutions({isVisual}) {
    const router = useRouter()
    const {setSearchParams, resetParams} = useSearchParams({}, router.query)
    const [flatCount, setFlatCount] = useState({});
    const dispatch = useDispatch()

    const handleChangeRouter = (name, value) => {
        resetParams()
        router.push(`/catalog/?${name}=${value}`)
        setSearchParams({name, value })
        dispatch({ type: types.RESET_FILTER })
    }

    useEffect(() => {
        let flats ={}
        list({balcony: true}).then(data => {
            setFlatCount((prevValue) => ({...prevValue, balcony: data.flat_count }));
        });
        list({furnished: true}).then(data => {
            setFlatCount((prevValue) => ({...prevValue, furnished: data.flat_count }));
        });
        list({wardrobe: true}).then(data => {
            setFlatCount((prevValue) => ({...prevValue, wardrobe: data.flat_count }));
        });
        list({rooms: [1]}).then(data => {
            setFlatCount((prevValue) => ({...prevValue, studio: data.flat_count }));
        });
        list({windows_surround: 'yard'}).then(data => {
            setFlatCount((prevValue) => ({...prevValue, yard: data.flat_count }));
        });
        list({flat_template_type: 'corner'}).then(data => {
            setFlatCount((prevValue) => ({...prevValue, corner: data.flat_count }));
        });
        list({windows_surround: 'streetnyard'}).then(data => {
            flats = {...flats, streetnyard: data.flat_count};
            setFlatCount((prevValue) => ({...prevValue, streetnyard: data.flat_count }));
        });
        list({is_discount_from_api: true}).then(data => {
          setFlatCount((prevValue) => ({...prevValue, is_discount_from_api: data.flat_count }));
        });

    }, []);

    return (
        <div className={clsx(isVisual ? "visual__solutions solutions" : "parametrs__solution")}>
            {isVisual ? <h3 className="solutions__title">Готовые решения</h3>:<div className={"parametrs__solution-title"}>Готовые решения</div>}
            <div className={clsx(isVisual ? "solutions__list" : "parametrs__solution-list")}>
              {flatCount?.is_discount_from_api > 0 && <Link href={{pathname: '/catalog', query: {is_discount_from_api: true}}}>
                <a onClick={() => handleChangeRouter('is_discount_from_api', true)}
                   className={clsx(isVisual ? "solutions__item" : "parametrs__solution-item", "solutions__item--sale")}>Выгода до {DISCOUNT}</a></Link>
              }
              {flatCount?.furnished > 0 && <Link href={{ pathname: '/catalog', query: {furnished: true} }}>
                <a  onClick={() => handleChangeRouter('furnished', true)} className={clsx(isVisual ? "solutions__item" : "parametrs__solution-item")}>Отделка White Box</a></Link>
              }
              {flatCount?.balcony > 0 && <Link href={{ pathname: '/catalog', query: {balcony: true} }}>
                    <a  onClick={() => handleChangeRouter('balcony', true)} className={clsx(isVisual ? "solutions__item" : "parametrs__solution-item")}>Балкон</a></Link>
                }
                {flatCount?.wardrobe > 0 && <Link href={{pathname: '/catalog', query: {wardrobe: true}}}>
                    <a onClick={() => handleChangeRouter('wardrobe', true)}
                       className={clsx(isVisual ? "solutions__item" : "parametrs__solution-item")}>Гардеробная</a></Link>
                }
                {flatCount?.studio > 0 && <Link href={{pathname: '/catalog', query: {rooms: [1]}}}>
                    <a onClick={() => handleChangeRouter('rooms', [1])} className={clsx(isVisual ? "solutions__item" : "parametrs__solution-item")}>Квартиры для
                        холостяков</a></Link>
                }
                {flatCount?.yard > 0 && <Link href={{pathname: '/catalog', query: {windows_surround: 'yard'}}}>
                    <a onClick={() => handleChangeRouter('windows_surround', 'yard')} className={clsx(isVisual ? "solutions__item" : "parametrs__solution-item")}>Вид
                        во двор</a></Link>
                }
                {flatCount?.corner > 0 && <Link href={{pathname: '/catalog', query: {flat_template_type: 'corner'}}}>
                    <a onClick={() => handleChangeRouter('flat_template_type', 'corner')}
                       className={clsx(isVisual ? "solutions__item" : "parametrs__solution-item")}>Больше света</a></Link>
                }
                {flatCount?.streetnyard > 0 && <Link href={{ pathname: '/catalog', query: { windows_surround: 'streetnyard' } }}>
                    <a onClick={() => handleChangeRouter('windows_surround', 'streetnyard')} className={clsx(isVisual ? "solutions__item" : "parametrs__solution-item")}>Окна в две стороны</a>
                </Link>
                }
            </div>
        </div>
    )
}
